import { Link } from 'gatsby';
import React from 'react'
import Layout from '../components/Layout.jsx'
import { useAuth } from "../hooks/useAuth";

export default function NotFound() {

  const { isLoggedIn } = useAuth()
  const browser = typeof window !== "undefined" && window

  return (
    <>
      {browser && (
        <Layout>
          <div className="notFound">
            <h2>Sidan kunde inte hittas</h2>
            <p>
              Detta kan bero på att länken är felaktig eller att sidan har
              flyttats
            </p>
            {isLoggedIn ? (
              <p>Försök igen eller gå vidare till mina sidor.</p>
            ) : (
              <p>Försök igen eller gå vidare till startsidan.</p>
            )}
            {isLoggedIn ? (
              <Link to="/user">
                <button>Till Mina sidor ›</button>
              </Link>
            ) : (
              <Link to="/">
                <button>Till Startsidan ›</button>
              </Link>
            )}
          </div>
        </Layout>
      )}
    </>
  )
}
